<template>
  <v-app>
    <v-app-bar app dark>
      <div class="d-flex align-center">
        <router-link style="text-decoration: none; color: inherit" to="/">
          <p class="shrink mt-2 font-weight-bold title">Ko-Mi-Trans</p>
        </router-link>
        <!-- <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
            transition="scale-transition"
            width="40"
          />
        </router-link>
        <router-link to="/">
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
            width="100"
          /> -->
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <!-- Footer -->
    <v-footer height="300" dark padless>
      <v-card flat tile width="100%" class="float-right">
        <v-card-text>
          <v-row>
            <v-col cols="0" xs="2" sm="4" md="6" lg="8" xl="8"></v-col>
            <v-col cols="6" xs="6" sm="4" md="3" lg="2" xl="2">
              <div class="mt-10">
                <h4>
                  <p>Seiten</p>
                </h4>
              </div>
              <router-link style="text-decoration: none; color: inherit" to="/"
                ><p>Home</p></router-link
              >
              <router-link
                style="text-decoration: none; color: inherit"
                to="/disclaimer"
                ><p>Disclaimer</p></router-link
              >
              <router-link
                style="text-decoration: none; color: inherit"
                to="/impressum"
                ><p>Impressum</p></router-link
              >
            </v-col>
            <v-col cols="6" xs="6" sm="4" md="3" lg="2" xl="2">
              <div class="mt-10">
                <h4><p>Kontakt</p></h4>
                <div>
                  <p>
                    Jens Koch<br />Unterdorf 13<br />
                    37434 L&uuml;tgenhausen
                  </p>
                  <p>
                    <v-icon small>mdi-phone</v-icon
                    ><a href="tel://+4955291024"> +49 5529 1024</a>
                  </p>
                  <p>
                    <v-icon small>mdi-mail</v-icon
                    ><a href="mailto:info@ko-mi-trans.de">
                      info@ko-mi-trans.de</a
                    >
                  </p>
                  <p>
                    <v-icon small>mdi-web</v-icon
                    ><a href="#"> www.ko-mi-trans.de</a>
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row class="mr-10">
                  <v-col>
                    <v-btn to="/impressum" text tile small>
                      <span>Impressum</span>
                    </v-btn>
                  </v-col>                  
                </v-row>
                <v-row class="mr-10">
                  <v-col>
                    <v-btn to="/disclaimer" text tile small>
                      <span>Disclaimer</span>
                    </v-btn>
                  </v-col>
                </v-row> -->
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
