var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-img',{attrs:{"src":require("../assets/hb.jpg"),"max-height":"600"}}),_c('v-sheet',{attrs:{"height":"500","dark":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h4 text-center pt-12"})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"id":"sh1","cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: 0,
              rootMargin: '0px 0px 0px 0px',
              delay: 0,
            },
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: 0,\n              rootMargin: '0px 0px 0px 0px',\n              delay: 0,\n            },\n          }"}],attrs:{"height":"100%"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.lazy1)?_c('v-card',{attrs:{"height":"100%","color":"grey darken-3"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-truck-outline")])],1),_c('v-card-text',{staticClass:"text-center text-h4"},[_vm._v("Moderner Fuhrpark")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v("Unser moderner Fuhrpark wird stets den aktuellen Anforderungen angepasst.")])],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect2,
            options: {
              threshold: 0,
              rootMargin: '0px 0px 0px 0px',
              delay: 200,
            },
          }),expression:"{\n            handler: onIntersect2,\n            options: {\n              threshold: 0,\n              rootMargin: '0px 0px 0px 0px',\n              delay: 200,\n            },\n          }"}],attrs:{"height":"100%"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.lazy2)?_c('v-card',{attrs:{"height":"100%","color":"grey darken-3"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-clock-outline")])],1),_c('v-card-text',{staticClass:"text-center text-h4"},[_vm._v("Pünktlich")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v("Unsere Transporte erreichen pünktlich Ihr Ziel.")])],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect3,
            options: {
              threshold: 0,
              rootMargin: '0px 0px 0px 0px',
              delay: 400,
            },
          }),expression:"{\n            handler: onIntersect3,\n            options: {\n              threshold: 0,\n              rootMargin: '0px 0px 0px 0px',\n              delay: 400,\n            },\n          }"}],attrs:{"height":"100%"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.lazy3)?_c('v-card',{attrs:{"height":"100%","color":"grey darken-3"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-application-export")])],1),_c('v-card-text',{staticClass:"text-center text-h4"},[_vm._v("Langjährige Erfahrung")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v("über 25 Jahre Erfahrung im sammeln und transportieren von Milch.")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.onIntersect4,
      options: {
        threshold: 0.8,
        rootMargin: '0px 0px 0px 0px',
        delay: 0,
      },
    }),expression:"{\n      handler: onIntersect4,\n      options: {\n        threshold: 0.8,\n        rootMargin: '0px 0px 0px 0px',\n        delay: 0,\n      },\n    }"}],staticStyle:{"background":"black","height":"400px"},attrs:{"height":"100%"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.lazy4)?_c('v-sheet',{attrs:{"color":"grey darken-3","height":"400","dark":""}},[_c('v-container',{staticClass:"pt-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticClass:"text-h4 text-center pt-5"},[_vm._v("Wir stellen uns vor")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticStyle:{"max-width":"500px"}},[_vm._v(" Wir sind ein Familienbetrieb mit mehr als 15 Mitarbeitern. Seit mehr als 20 Jahren sind wir spezialisiert aufs sammeln und transport von Rohmilch. Unsere Sammelgebiete sind in Niedersachsen und Hessen. Unser Fuhrpark umfasst verschiedene Typen von Milchsammelwagen. Somit können wir gewährleisten, dass jeder Kunde optimal angefahren und erfasst werden kann. Dies umfasst 2 Achs Zugmaschinen mit Anhänger, 3 Achs Zugmaschinen mit Tandemanhänger und Sattelzüge mit VSE Lenkung. ")])])],1)],1)],1):_vm._e()],1)],1),_c('v-parallax',{attrs:{"height":"400","src":require('@/assets/hb2.jpg')}}),_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.onIntersect5,
      options: {
        threshold: 0.8,
        rootMargin: '0px 0px 0px 0px',
        delay: 0,
      },
    }),expression:"{\n      handler: onIntersect5,\n      options: {\n        threshold: 0.8,\n        rootMargin: '0px 0px 0px 0px',\n        delay: 0,\n      },\n    }"}],staticStyle:{"background":"black","height":"400px"},attrs:{"height":"100%"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.lazy5)?_c('v-sheet',{attrs:{"color":"grey darken-3","height":"400","dark":""}},[_c('v-container',{staticClass:"pt-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticClass:"text-h4 text-center pt-5"},[_vm._v("Kontakt")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticStyle:{"max-width":"500px"}},[_vm._v(" Jens Koch "),_c('br'),_vm._v(" Unterdorf 13 "),_c('br'),_vm._v(" 37434 Lütgenhausen "),_c('br'),_vm._v(" Email: "),_c('a',{attrs:{"href":"mailto:info@ko-mi-trans.de"}},[_vm._v("info@ko-mi-trans.de")]),_c('br'),_vm._v(" Telefon: +49 5529 / 1024 ")])])],1)],1)],1):_vm._e()],1)],1),_c('v-sheet',{attrs:{"height":"400"}},[_c('vl-map',{ref:"map",staticClass:"map",attrs:{"mouseWheelZoom":false,"load-tiles-while-animating":true,"load-tiles-while-interacting":true,"data-projection":"EPSG:4326","controls":true},on:{"mounted":_vm.onMapMounted}},[_c('vl-view',{attrs:{"zoom":_vm.map.zoom,"center":_vm.map.center2}}),_c('vl-feature',{attrs:{"id":"position-feature"}},[_c('vl-geom-point',{attrs:{"coordinates":_vm.map.center2}}),_c('vl-style-box',[_c('vl-style-icon',{attrs:{"src":require('@/assets/mapMarker.png'),"scale":0.08,"anchor":[0.5, 1]}})],1)],1),_c('vl-layer-tile',{attrs:{"id":"osm"}},[_c('vl-source-osm')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }