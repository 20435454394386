<template>
  <div>
    <v-img src="../assets/hb.jpg" max-height="600">
      <!-- <v-card>
            <v-card-title>                
                Hello
            </v-card-title>
        </v-card>-->
    </v-img>

    <!-- Angeben -->
    <v-sheet height="500" dark>
      <v-row>
        <v-col cols="12">
          <div class="text-h4 text-center pt-12"></div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col id="sh1" cols="12" sm="12" md="3" lg="3" xl="3">
          <v-sheet
            height="100%"
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: 0,
                rootMargin: '0px 0px 0px 0px',
                delay: 0,
              },
            }"
          >
            <transition name="slide-fade">
              <v-card v-if="lazy1" height="100%" color="grey darken-3">
                <v-card-text class="text-center">
                  <v-icon x-large>mdi-truck-outline</v-icon>
                </v-card-text>
                <v-card-text class="text-center text-h4"
                  >Moderner Fuhrpark</v-card-text
                >
                <v-card-text class="text-center"
                  >Unser moderner Fuhrpark wird stets den aktuellen
                  Anforderungen angepasst.</v-card-text
                >
              </v-card>
            </transition>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
          <v-sheet
            height="100%"
            v-intersect="{
              handler: onIntersect2,
              options: {
                threshold: 0,
                rootMargin: '0px 0px 0px 0px',
                delay: 200,
              },
            }"
          >
            <transition name="slide-fade">
              <v-card v-if="lazy2" height="100%" color="grey darken-3">
                <v-card-text class="text-center">
                  <v-icon x-large>mdi-clock-outline</v-icon>
                </v-card-text>
                <v-card-text class="text-center text-h4">Pünktlich</v-card-text>
                <v-card-text class="text-center"
                  >Unsere Transporte erreichen pünktlich Ihr Ziel.</v-card-text
                >
              </v-card>
            </transition>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
          <v-sheet
            height="100%"
            v-intersect="{
              handler: onIntersect3,
              options: {
                threshold: 0,
                rootMargin: '0px 0px 0px 0px',
                delay: 400,
              },
            }"
          >
            <transition name="slide-fade">
              <v-card height="100%" color="grey darken-3" v-if="lazy3">
                <v-card-text class="text-center">
                  <v-icon x-large>mdi-application-export</v-icon>
                </v-card-text>
                <v-card-text class="text-center text-h4"
                  >Langjährige Erfahrung</v-card-text
                >
                <v-card-text class="text-center"
                  >über 25 Jahre Erfahrung im sammeln und transportieren von
                  Milch.</v-card-text
                >
              </v-card>
            </transition>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- Inventation -->
    <v-sheet
      height="100%"
      style="background: black; height: 400px"
      v-intersect="{
        handler: onIntersect4,
        options: {
          threshold: 0.8,
          rootMargin: '0px 0px 0px 0px',
          delay: 0,
        },
      }"
    >
      <transition name="slide-fade">
        <v-sheet v-if="lazy4" color="grey darken-3" height="400" dark>
          <v-container class="pt-12">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="text-h4 text-center pt-5">Wir stellen uns vor</div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div style="max-width: 500px">
                  Wir sind ein Familienbetrieb mit mehr als 15 Mitarbeitern.
                  Seit mehr als 20 Jahren sind wir spezialisiert aufs sammeln
                  und transport von Rohmilch. Unsere Sammelgebiete sind in
                  Niedersachsen und Hessen. Unser Fuhrpark umfasst verschiedene
                  Typen von Milchsammelwagen. Somit können wir gewährleisten,
                  dass jeder Kunde optimal angefahren und erfasst werden kann.
                  Dies umfasst 2 Achs Zugmaschinen mit Anhänger, 3 Achs
                  Zugmaschinen mit Tandemanhänger und Sattelzüge mit VSE
                  Lenkung.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </transition>
    </v-sheet>

    <!-- Parallax -->
    <v-parallax height="400" :src="require('@/assets/hb2.jpg')"> </v-parallax>

    <!-- Contact -->
    <v-sheet
      height="100%"
      style="background: black; height: 400px"
      v-intersect="{
        handler: onIntersect5,
        options: {
          threshold: 0.8,
          rootMargin: '0px 0px 0px 0px',
          delay: 0,
        },
      }"
    >
      <transition name="slide-fade">
        <v-sheet v-if="lazy5" color="grey darken-3" height="400" dark>
          <v-container class="pt-12">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="text-h4 text-center pt-5">Kontakt</div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div style="max-width: 500px">
                  Jens Koch <br />
                  Unterdorf 13 <br />
                  37434 Lütgenhausen <br />
                  Email:
                  <a href="mailto:info@ko-mi-trans.de">info@ko-mi-trans.de</a>
                  <br />
                  Telefon: +49 5529 / 1024
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </transition>
    </v-sheet>

    <!-- Map -->
    <v-sheet height="400">
      <vl-map
        class="map"
        ref="map"
        @mounted="onMapMounted"
        :mouseWheelZoom="false"
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326"
        :controls="true"
      >
        <vl-view :zoom="map.zoom" :center="map.center2"></vl-view>

        <vl-feature id="position-feature">
          <vl-geom-point :coordinates="map.center2"></vl-geom-point>
          <vl-style-box>
            <vl-style-icon
              :src="require('@/assets/mapMarker.png')"
              :scale="0.08"
              :anchor="[0.5, 1]"
            ></vl-style-icon>
          </vl-style-box>
        </vl-feature>

        <vl-layer-tile id="osm">
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>
      </vl-map>
    </v-sheet>
  </div>
</template>

<script>
import { FullScreen, ZoomSlider } from "ol/control";
/* eslint-disable */
import { DragPan, DoubleClickZoom } from "ol/interaction";
export default {
  data() {
    return {
      lazy1: false,
      lazy2: false,
      lazy3: false,
      lazy4: false,
      lazy5: false,
      map: {
        zoom: 10,
        center: [51.58582643670156, 10.274942752796157],
        center2: [10.274942752796157, 51.58582643670156],
      },
      //myTransition: createSimpleTransition('my-transition'),
    };
  },

  created() {},

  methods: {
    onIntersect(entries, observer, isIntersecting) {
      this.lazy1 = isIntersecting;
    },
    onIntersect2(entries, observer, isIntersecting) {
      this.lazy2 = isIntersecting;
    },
    onIntersect3(entries, observer, isIntersecting) {
      this.lazy3 = isIntersecting;
    },
    onIntersect4(entries, observer, isIntersecting) {
      this.lazy4 = isIntersecting;
    },
    onIntersect5(entries, observer, isIntersecting) {
      this.lazy5 = isIntersecting;
    },
    onMapMounted() {
      // now ol.Map instance is ready and we can work with it directly
      this.$refs.map.$map
        .getControls()
        .extend([new FullScreen(), new ZoomSlider()]);
      this.$refs.map.$map.getInteractions().clear(); //Remove all Interactions (MouseWheelZoom, etc.)
      this.$refs.map.$map
        .getInteractions()
        .extend([new DragPan(), new DoubleClickZoom()]); //Add Required Interactions
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(80px);
  opacity: 0.5;
}

.slide-fade2-enter-active {
  transition: all 1s ease;
}
.slide-fade2-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade2-enter, .slide-fade2-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(80px);
  opacity: 0.5;
}
</style>